import React from "react"
import Layout from '../components/layout'
import { Container, Row, Col } from "react-bootstrap"
import CardPayment from "../components/card-payment"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"

const CartScreen = () => {

    const stripePromise = loadStripe(
        `${process.env.GATSBY_STRIPE_PUBLISHABLE_KEY}`
    )

    return (
        <Layout>
            <Container>
                <Elements stripe={stripePromise}>
                    <CardPayment />
                </Elements>
            </Container>
        </Layout>
    )
}
export default CartScreen